import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Cancel, Add } from '@mui/icons-material'

import convertBase64 from '../../../methods/convertBase64'

const MainContainerStyled = styled(Box)(({ theme }) => ({
	height: 0,
	width: '100%',
	paddingTop: '177.77%', // 9/16
	position: 'relative'
}))

const CardContainerStyled = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	'&:hover': {
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	},
	borderRadius: 3,
	backgroundColor: theme.custom.colors.kozmikGray,
	padding: 22,
	zIndex: 2,
	cursor: 'pointer'
}))

const AddContainerStyled = styled(Box)(({ theme }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}))

const TemplateImageItem = (props) => {
	const [imageSrc, setImageSrc] = useState(props.image)

	const picker = useRef(null)
	const pickerClickHandler = () => {
		picker.current.click()
	}

	const pickImage = async (e) => {
		if (e.target.files[0]) {
			await convertBase64(e.target.files[0]).then((result) => {
				props.setImage(result.data)
				const imageUrl = URL.createObjectURL(e.target.files[0])
				setImageSrc(imageUrl)
			})
		}
	}

	const clearPicker = (e) => {
		e.stopPropagation()
		picker.current.value = null
		props.setImage(null)
		setImageSrc(null)
	}

	return (
		<>
			<input
				ref={picker}
				// refs='file'
				accept={props?.accept || '.jpg,.jpeg,.png'}
				style={{ display: 'none' }}
				type='file'
				onChange={(e) => {
					pickImage(e)
				}}
			/>
			<MainContainerStyled>
				<CardContainerStyled elevation={0} onClick={pickerClickHandler}>
					{imageSrc && (
						<Cancel
							style={{
								position: 'absolute',
								right: 5,
								top: 5,
								cursor: 'pointer'
							}}
							onClick={clearPicker}
						/>
					)}

					{imageSrc ? (
						<img
							src={imageSrc}
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'contain'
							}}
						/>
					) : (
						<AddContainerStyled>
							<Add
								style={{
									width: 32,
									height: 32
								}}
							/>
						</AddContainerStyled>
					)}
				</CardContainerStyled>
			</MainContainerStyled>
		</>
	)
}

export default TemplateImageItem
