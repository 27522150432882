import React, { useEffect, useState } from 'react'

// import '@stoplight/elements/styles.min.css'
// import { API } from '@stoplight/elements'

import { RedocStandalone } from 'redoc'

import dataOpenAPIClient from '../../../services/dataOpenAPIClient'
import showToastResponseError from '../../../methods/showToastResponseError'
import useMediaQuery from '@mui/material/useMediaQuery'

const ApiDoc = (props) => {
	const { tableName, appId } = props
	const [apiDescription, setApiDescription] = useState('')

	const builderOnMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

	const getYamlDocument = async (tableName, appId) => {
		return dataOpenAPIClient
			.getAPIDocument(appId, tableName)
			.then((response) => {
				if (response?.data) {
					setApiDescription(response.data)
				} else {
					showToastResponseError(response)
				}
			})
	}

	useEffect(() => {
		getYamlDocument(tableName, appId)
	}, [tableName, appId])

	return (
		apiDescription && (
			<div
				style={
					builderOnMobile
						? { padding: 30, width: '100%', height: '100%' }
						: { width: '100%', height: '100%' }
				}
			>
				{/* <API
					apiDescriptionDocument={apiDescription}
					router='hash'
					layout={builderOnMobile ? 'stacked' : 'sidebar'}
					hideSchemas={true}
				/> */}

				<RedocStandalone
					spec={apiDescription}
					options={{
						layout: 'stacked',
						hideLoading: true,
						disableSearch: true,
						disableSidebar: builderOnMobile ? true : false,
						expandSingleSchemaField: true,
						expandResponses: true,
						expandDefaultServerVariables: true,
						jsonSampleExpandLevel: 'all',
						schemaExpansionLevel: 'all'
					}}
					onLoaded={(error) => {
						if (!error) {
							if (document) {
								const container = document.getElementsByTagName('li')[0]
								if (container && container.role == 'menuitem') {
									container.click()
								}
							}
						}
					}}
				/>
			</div>
		)
	)
}

export default ApiDoc
