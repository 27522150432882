import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import {
	Typography,
	Box,
	Button,
	CardMedia,
	CircularProgress
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { CheckCircle } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

import { default as successImage } from '../../../assets/images/success.png'

import KozmikModal from '../../molecules/kozmikModal/KozmikModal'
import KozmikMenuItem from '../../molecules/kozmikMenuItem/KozmikMenuItem'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import SelectBox from '../../molecules/selectBox/SelectBox'
import TemplateImageList from '../templateImageList/TemplateImageList'
import HistoryItem from '../../molecules/historyItem/HistoryItem'
import Badge from '../../atoms/badge/Badge'

import templateClient from '../../../services/templateClient'
import showToastResponseError from '../../../methods/showToastResponseError'

const GridContainerStyled = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100%',
	overflowY: 'auto'
}))

const LeftBoxStyled = styled(Grid)(({ theme }) => ({
	padding: '45px 20px 20px 20px',
	backgroundColor: theme.custom.colors.kozmikGray,
	flexDirection: 'column',
	justifyContent: 'space-between',
	overflowY: 'auto',
	[theme.breakpoints.up('sm')]: {
		height: '100%'
	}
}))

const RightBoxStyled = styled(Grid)(({ theme }) => ({
	padding: '45px 20px 20px 20px',
	backgroundColor: '#ffffff',
	overflowY: 'auto',
	[theme.breakpoints.down('md')]: {
		padding: '25px 20px 20px 20px'
	},
	[theme.breakpoints.up('sm')]: {
		height: '100%'
	}
}))

const MainTitleStyled = styled(Typography)(({ theme }) => ({
	marginBottom: 25,
	fontSize: 16,
	fontWeight: '600'
}))

const ContentTitleStyled = styled(Typography)(({ theme }) => ({
	marginBottom: 10,
	fontSize: 16,
	fontWeight: '600'
}))

const ContentDescStyled = styled(Typography)(({ theme }) => ({
	marginBottom: 15,
	fontSize: 14
}))

const SubTitleStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	fontWeight: '600'
}))

const SuccessTitleStyled = styled(Typography)(({ theme }) => ({
	marginLeft: 10,
	marginTop: 20,
	fontWeight: '600',
	fontSize: 16
}))

const SuccessTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	marginBottom: 10
}))

const ButtonTextStyled = styled(Typography)(({ theme }) => ({
	color: '#ffffff',
	fontSize: 14,
	fontWeight: 'bold'
}))

const SuccessModalBoxStyled = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100%',
	padding: 30
}))

const DefaultInputStyled = styled(KozmikTextField)(({ theme }) => ({
	fontSize: 14,
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: 6,
	border: 'none'
}))

const CheckCircleStyled = styled(CheckCircle)((props) =>
	props.tabCompleted
		? {
				fontSize: 15,
				color: props.theme.custom.colors.kozmikMatGreen
		  }
		: {
				fontSize: 15,
				color: '#BBBBBD'
		  }
)

const TextStyled = styled(Typography)(({ theme }) => ({ fontSize: 12 }))

const LinkStyled = styled('a')(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	textDecoration: 'underline',
	cursor: 'pointer'
}))

const style = {
	input: (props, theme) => {
		return {
			fontSize: 14,
			backgroundColor: theme.custom.colors.kozmikGray,
			borderRadius: '6px !important',
			border: 'none',
			overflow: 'hidden',
			'& .MuiOutlinedInput-root': {
				padding: 0
			},
			'& .MuiOutlinedInput-adornedStart': {
				paddingLeft: 0
			},
			'& .MuiOutlinedInput-adornedEnd': {
				paddingRight: 0
			}
		}
	}
}

const TemplateSubmit = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	let { appId } = useParams()

	const [selectedTab, setSelectedTab] = useState(0)
	const [history, setHistory] = useState([])

	const [successVisible, setSuccessVisible] = useState(false)

	/****APP_NAME***************************/
	const [appName, setAppName] = useState('')

	const changeAppName = (e) => {
		setAppName(e.target.value)
	}

	/****DESCRIPTION***************************/
	const [description, setDescription] = useState('')

	const changeDescription = (e) => {
		setDescription(e.target.value)
	}

	/****HIGHLIGHTS***************************/
	const [highlights, setHighlights] = useState('')

	const changeHighlights = (e) => {
		setHighlights(e.target.value)
	}

	/****TERMS***************************/
	// const [termsAccepted, setTermsAccepted] = useState(false)

	// const changeTermAccepted = (e) => {
	// 	setTermsAccepted(e.target.checked)
	// }

	/****PRICE***************************/
	const [price, setPrice] = useState(0)

	const priceOptions = [
		{
			label: 'Free', //TODO:Localization
			value: 0
		}
	]

	const priceChange = (e) => {
		setPrice(e.target.value)
	}

	/****FEATURES***************************/
	const [features, setFeatures] = useState([])
	const [featuresOptions, setFeaturesOptions] = useState([])

	const featuresChange = (e) => {
		setFeatures(
			typeof e.target.value === 'string'
				? e.target.value.split(',')
				: e.target.value
		)
	}

	const getTags = () => {
		templateClient
			.getTags()
			.then((response) => {
				if (response?.data?.success) {
					setFeaturesOptions(response.data?.data || [])
				} else {
					setFeaturesOptions([])
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setFeaturesOptions([])
			})
	}

	/****CATEGORIES***************************/
	const [categories, setCategories] = useState([])
	const [categoriesOptions, setCategoriesOptions] = useState([])

	const categoriesChange = (e) => {
		setCategories(
			typeof e.target.value === 'string'
				? e.target.value.split(',')
				: e.target.value
		)
	}

	const getCategories = () => {
		templateClient
			.getCategories()
			.then((response) => {
				if (response?.data?.success) {
					setCategoriesOptions(response.data?.data || [])
				} else {
					setCategoriesOptions([])
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setCategoriesOptions([])
			})
	}

	/****IMAGES***************************/
	const [image1, setImage1] = useState(null)
	const [image2, setImage2] = useState(null)
	const [image3, setImage3] = useState(null)

	/****SUBMIT***************************/
	const [submitting, setSubmitting] = useState(false)

	const submit = () => {
		setSubmitting(true)
		const submitData = {
			Name: appName,
			Description: description,
			Highlights: highlights,
			Categories: categories,
			Tags: features,
			Price: price,
			Image1: image1,
			Image2: image2,
			Image3: image3
		}

		templateClient
			.submitTemplate(appId, submitData)
			.then((response) => {
				if (response?.data?.success) {
					props.hideModal()
					setSuccessVisible(true)
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch((error) => {
				setSubmitting(false)
			})
	}

	/****************************************/
	const tab0Completed = !!appName && !!description && !!highlights //&& termsAccepted
	const tab1Completed = !!features?.length && !!categories?.length
	const tab2Completed = image1 && image2 && image3

	const submitButtonActive = tab0Completed && tab1Completed && tab2Completed

	const getHistory = () => {
		templateClient.getSubmitHistory(appId).then((response) => {
			if (response?.data?.success) {
				setHistory(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const initModal = () => {
		setSelectedTab(0)
		setAppName('')
		setDescription('')
		setHighlights('')
		// setTermsAccepted(false)
		setPrice(0)
		setFeatures([])
		setCategories([])
		getCategories()
		getTags()
		getHistory()
		setImage1(null)
		setImage2(null)
		setImage3(null)
		setSubmitting(false)
	}

	useEffect(() => {
		props.visible && initModal()
	}, [props.visible])

	return (
		<>
			<KozmikModal visible={props.visible} hideModal={props.hideModal}>
				<GridContainerStyled container>
					<LeftBoxStyled container size={{ xs: 12, sm: 4 }}>
						<Grid
							display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }}
							style={{ marginBottom: 20 }}
						>
							<Button
								fullWidth
								disabled={!submitButtonActive || submitting}
								size='small'
								variant='contained'
								onClick={() => submit()}
								sx={{
									textTransform: 'none',
									backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
									opacity: submitButtonActive ? 1 : 0.4,
									boxShadow: 'none !important',
									height: '40px',
									borderRadius: '8px'
								}}
							>
								<Box
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<ButtonTextStyled>
										{t('publishModule.templateModal.publishBtn')}
									</ButtonTextStyled>
									{submitting && (
										<CircularProgress
											size={20}
											sx={{
												margin: '10px',
												color: '#ffffff'
											}}
										/>
									)}
								</Box>
							</Button>
						</Grid>
						<Grid>
							<MainTitleStyled
								display={{
									xs: 'none',
									sm: 'none',
									md: 'none',
									lg: 'block'
								}}
							>
								{t('publishModule.templateModal.title')}
							</MainTitleStyled>
							<KozmikMenuItem
								text={t('publishModule.templateModal.appInfoMenu')}
								onClick={() => {
									setSelectedTab(0)
								}}
								isActive={selectedTab === 0}
								adornment={<CheckCircleStyled tabCompleted={tab0Completed} />}
							/>
							<KozmikMenuItem
								text={t('publishModule.templateModal.listingMenu')}
								onClick={() => {
									setSelectedTab(1)
								}}
								isActive={selectedTab === 1}
								adornment={<CheckCircleStyled tabCompleted={tab1Completed} />}
							/>
							<KozmikMenuItem
								text={t('publishModule.templateModal.screensMenu')}
								onClick={() => {
									setSelectedTab(2)
								}}
								isActive={selectedTab === 2}
								adornment={<CheckCircleStyled tabCompleted={tab2Completed} />}
							/>
							<KozmikMenuItem
								text={t('publishModule.templateModal.history')}
								onClick={() => setSelectedTab(3)}
								isActive={selectedTab === 3}
								adornment={<Badge text={history?.length || 0} />}
							/>
						</Grid>
						<Grid display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>
							<Button
								fullWidth
								disabled={!submitButtonActive || submitting}
								size='small'
								variant='contained'
								onClick={() => submit()}
								sx={{
									textTransform: 'none',
									backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
									opacity: submitButtonActive ? 1 : 0.4,
									boxShadow: 'none !important',
									height: '40px',
									borderRadius: '8px'
								}}
							>
								<Box
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<ButtonTextStyled>
										{t('publishModule.templateModal.publishBtn')}
									</ButtonTextStyled>
									{submitting && (
										<CircularProgress
											size={20}
											sx={{
												margin: '10px',
												color: '#ffffff'
											}}
										/>
									)}
								</Box>
							</Button>
						</Grid>
					</LeftBoxStyled>
					<RightBoxStyled size={{ xs: 12, sm: 8 }}>
						{selectedTab === 0 && (
							<>
								<ContentTitleStyled>
									{t('publishModule.templateModal.appInfoTitle')}
								</ContentTitleStyled>
								<ContentDescStyled>
									{t('publishModule.templateModal.appInfoHighlights')}
								</ContentDescStyled>

								<Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.appName')}
									</SubTitleStyled>
									<KozmikTextField
										sx={style.input(props, theme)}
										fullWidth
										placeholder={t(
											'publishModule.templateModal.appNamePlaceholder'
										)}
										value={appName}
										onChange={changeAppName}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.description')}
									</SubTitleStyled>
									<KozmikTextField
										sx={style.input(props, theme)}
										fullWidth
										placeholder={t(
											'publishModule.templateModal.descriptionPlaceholder'
										)}
										value={description}
										onChange={changeDescription}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.highlights')}
									</SubTitleStyled>
									<DefaultInputStyled
										fullWidth
										placeholder={t(
											'publishModule.templateModal.highlightsPlaceholder'
										)}
										value={highlights}
										onChange={changeHighlights}
										multiline
										rows={2}
									/>
								</Box>

								{/* <Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.termsOfUse')}
									</SubTitleStyled>
									<TextStyled>
										{t('publishModule.templateModal.pleaseRead')}{' '}
										<LinkStyled>
											{t('publishModule.templateModal.termsLinkText')}
										</LinkStyled>
									</TextStyled>
									<TextStyled>
										{t('publishModule.templateModal.guidelines')}
									</TextStyled>

									<Box style={{ display: 'flex', alignItems: 'center' }}>
										<KozmikCheckBox
											checked={termsAccepted}
											onChange={changeTermAccepted}
										/>
										<TextStyled>
											{t('publishModule.templateModal.acceptText')}
										</TextStyled>
									</Box>
								</Box> */}
							</>
						)}

						{selectedTab === 1 && (
							<>
								<ContentTitleStyled>
									{t('publishModule.templateModal.listingTitle')}
								</ContentTitleStyled>
								<ContentDescStyled>
									{t('publishModule.templateModal.listingDesc')}
								</ContentDescStyled>

								<Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.features')}
									</SubTitleStyled>
									<SelectBox
										sx={style.input(props, theme)}
										onChange={featuresChange}
										value={features}
										data={featuresOptions}
										textKey='Name'
										valueKey='Id'
										multiple
										renderValue={(selected) => {
											if (selected.length === 0) {
												return t(
													'publishModule.templateModal.featuresPlaceholder'
												)
											} else {
												return featuresOptions
													?.filter((x) => selected?.includes(x.Id))
													?.map((x) => x.Name)
													?.join(',')
											}
										}}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.categories')}
									</SubTitleStyled>
									<SelectBox
										sx={style.input(props, theme)}
										onChange={categoriesChange}
										value={categories}
										data={categoriesOptions}
										textKey='Name'
										valueKey='Id'
										multiple
										renderValue={(selected) => {
											if (selected.length === 0) {
												return t(
													'publishModule.templateModal.categoriesPlaceholder'
												)
											} else {
												return categoriesOptions
													?.filter((x) => selected?.includes(x.Id))
													?.map((x) => x.Name)
													?.join(',')
											}
										}}
									/>
								</Box>

								<Box style={{ marginBottom: 10 }}>
									<SubTitleStyled>
										{t('publishModule.templateModal.price')}
									</SubTitleStyled>
									<SelectBox
										sx={style.input(props, theme)}
										onChange={priceChange}
										value={price}
										data={priceOptions}
										textKey='label'
										valueKey='value'
										// placeholder={t(
										// 	'publishModule.templateModal.pricePlaceholder'
										// )}
									/>
								</Box>
							</>
						)}

						{selectedTab === 2 && (
							<>
								<ContentTitleStyled>
									{t('publishModule.templateModal.screensTitle')}
								</ContentTitleStyled>
								<ContentDescStyled>
									{t('publishModule.templateModal.screensDesc')}
								</ContentDescStyled>

								<TemplateImageList
									image1={image1}
									image2={image2}
									image3={image3}
									setImage1={setImage1}
									setImage2={setImage2}
									setImage3={setImage3}
								/>
							</>
						)}

						{selectedTab === 3 && (
							<>
								<ContentTitleStyled>
									{t('publishModule.templateModal.historyTitle')}
								</ContentTitleStyled>

								{history?.length > 0 ? (
									history.map((item, index) => (
										<HistoryItem platform='template' item={item} key={index} />
									))
								) : (
									<ContentDescStyled>
										{t('publishModule.templateModal.notPublished')}
									</ContentDescStyled>
								)}
							</>
						)}
					</RightBoxStyled>
				</GridContainerStyled>
			</KozmikModal>

			<KozmikModal
				visible={successVisible}
				hideModal={() => setSuccessVisible(false)}
				flexHeight
				sm
			>
				<SuccessModalBoxStyled container justifyContent='center'>
					<Grid
						container
						size={{ xs: 12 }}
						alignItems='center'
						style={{ marginBottom: 20 }}
					>
						<CardMedia
							image={successImage}
							component='img'
							style={{ width: 52, height: 52 }}
						/>
						<SuccessTitleStyled>
							{t('publishModule.templateModal.success.title')}
						</SuccessTitleStyled>
					</Grid>
					<Grid size={{ xs: 12 }} style={{ marginBottom: 20 }}>
						<SuccessTextStyled>
							{t('publishModule.templateModal.success.paragraph1')}
						</SuccessTextStyled>
					</Grid>
					<Grid container size={{ xs: 12 }} justifyContent='center'>
						<Button
							onClick={() => setSuccessVisible(false)}
							size='small'
							variant='contained'
							sx={{
								textTransform: 'none',
								backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
								boxShadow: 'none !important',
								height: '40px',
								width: '216px',
								borderRadius: '4px'
							}}
						>
							<ButtonTextStyled>
								{t('publishModule.templateModal.success.button')}
							</ButtonTextStyled>
						</Button>
					</Grid>
				</SuccessModalBoxStyled>
			</KozmikModal>
		</>
	)
}

export default TemplateSubmit
