import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import {
	Button,
	Box,
	Typography,
	Popover,
	InputAdornment,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	CardMedia
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ArrowBackIos, ContentCopy, OpenInNew } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QRCode from 'qrcode.react'

import TagInput from '../../atoms/tagInput/TagInput'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import SquareOptions from '../../molecules/squareOprions/SquareOptions'
import PreviewButtons from '../../molecules/previewButtons/PreviewButtons'
import TemplateSubmit from '../../organisms/templateSubmit/TemplateSubmit'
import KozmikModal from '../../molecules/kozmikModal/KozmikModal'
import MediaPicker from '../../atoms/mediaPicker/MediaPicker'
import HistoryItem from '../../molecules/historyItem/HistoryItem'

import appUserClient from '../../../services/appUserClient'
import appClient from '../../../services/appClient'

import { setDetail } from '../../../redux/actions/appDataActions'

import showToastResponseError from '../../../methods/showToastResponseError'
import timeoutDelay from '../../../methods/timeoutDelay'

import { default as successImage } from '../../../assets/images/success.png'

import { ReactComponent as PublishWeb } from '../../../assets/icons/Publish/PublishWeb.svg'
import { ReactComponent as PublishAppStore } from '../../../assets/icons/Publish/PublishAppStore.svg'
import { ReactComponent as PublishGooglePlay } from '../../../assets/icons/Publish/PublishGooglePlay.svg'

import Kozmik from '../../../assets/images/Kozmik.png'

const style = {
	qrCode: {
		padding: 10,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	}
}

const MainContainerStyled = styled('div')(({ theme }) => ({
	border: `1px solid ${theme.custom.colors.gray}`,
	backgroundColor: '#FFFFFF',
	padding: '12px 12px',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	height: 64,
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	[theme.breakpoints.down('md')]: {
		height: 'unset'
	}
}))

const LeftContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	paddingLeft: '10px',
	width: 'calc(16.66% + 75px)',
	[theme.breakpoints.down('md')]: {
		width: '60%'
	}
}))

const CenterContainerStyled = styled('div')(({ theme }) => ({
	justifyContent: 'center',
	alignItems: 'center',
	[theme.breakpoints.down('md')]: {
		display: 'none !important'
		// paddingLeft: 75,
		// width: '100%'
	}
}))

const RightContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	width: '25%',
	textAlign: 'right',
	justifyContent: 'flex-end',
	[theme.breakpoints.down('md')]: {
		width: '40%'
	}
}))

const ModalContainerStyled = styled(Box)(({ theme }) => ({
	width: 400,
	maxHeight: 675,
	padding: 30,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	[theme.breakpoints.down('md')]: {
		width: 'unset'
	}
}))

const TextBoxStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}))

const TextNameStyled = styled(Typography)(({ theme }) => ({
	color: '#000000',
	fontSize: '14px',
	lineHeight: '19px',
	fontWeight: 'bold',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
}))

const ModalQrContainerStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	marginBottom: 40
}))

const ModalLinkContainerStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	marginLeft: 20,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start'
}))

const InputStyled = styled(KozmikTextField)(({ theme }) => ({
	fontSize: 14,
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: 6,
	border: 'none',
	'& .MuiOutlinedInput-root': {
		padding: 0
	},
	'& .MuiOutlinedInput-adornedStart': {
		paddingLeft: 0
	},
	'& .MuiOutlinedInput-adornedEnd': {
		paddingRight: 0
	}
}))

const AppLinkAdornmentStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	padding: '16px 12px',
	color: theme.custom.colors.textPrimary,
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center'
}))

const AdornmentTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	backgroundColor: theme.custom.colors.gray,
	padding: '16px 6px',
	color: theme.custom.colors.textPrimary
}))

const AdornmentIconStyled = styled(OpenInNew)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	marginRight: 3
}))

const SuccessModalBoxStyled = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100%',
	padding: 30
}))

const SuccessTitleStyled = styled(Typography)(({ theme }) => ({
	marginLeft: 10,
	marginTop: 20,
	fontWeight: '600',
	fontSize: 16
}))

const SuccessSubTitleStyled = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	fontSize: 12,
	color: theme.custom.colors.darkGray
}))

const BadgeStyled = styled('span')(({ theme }) => ({
	fontSize: 9,
	fontWeight: 'bold',
	borderRadius: 7.5,
	padding: '2px 8px',
	position: 'absolute',
	top: 5,
	right: 5,
	backgroundImage:
		'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
}))

const IsPublishedBadgeStyled = styled(Typography)(({ theme }) => ({
	backgroundColor: '#56d87b',
	color: 'white',
	padding: '1px 5px',
	marginLeft: '20px',
	borderRadius: '10px',
	fontSize: '9px',
	lineHeight: '11px',
	fontWeight: 'bold',
	[theme.breakpoints.down('sm')]: {
		marginLeft: '5px'
	}
}))

const ModalTitleStyled = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontWeight: '600',
	color: '#000000'
}))

const ModalSubTitleStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: '#000000',
	marginBottom: 30
}))

const ModalButtonTextStyled = styled(Typography)(({ theme }) => ({
	color: '#ffffff',
	fontSize: 14,
	fontWeight: '600',
	textAlign: 'left',
	textTransform: 'none',
	whiteSpace: 'nowrap'
}))

const ModalMultilineInputStyled = styled(KozmikTextField)(({ theme }) => ({
	marginTop: 10,
	borderRadius: 4,
	backgroundColor: theme.custom.colors.kozmikGray,
	fontSize: '14px',
	color: theme.custom.colors.textPrimary
}))

const ShareStyled = styled(Button)(({ theme }) => ({
	borderRadius: '4px',
	width: '98px',
	height: '35px',
	marginRight: '2%',
	'&:hover': {
		backgroundColor: 'transparent'
	}
}))

const ShareTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 'bold',
	textAlign: 'left',
	textTransform: 'none',
	color: theme.custom.colors.kozmikDarkBlue
}))

const PublishStyled = styled(Button)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikDarkBlue,
	borderRadius: '4px',
	width: '98px',
	height: '35px',
	'&:hover': {
		backgroundColor: theme.custom.colors.kozmikDarkBlue
	}
}))

const PublishTextStyled = styled(Typography)(({ theme }) => ({
	color: '#FFFFFF',
	fontSize: '14px',
	fontWeight: 'bold',
	textAlign: 'left',
	textTransform: 'none'
}))

const CopyTextStyled = styled(Typography)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12,
	fontWeight: '600',
	textAlign: 'left',
	textTransform: 'none'
}))

const CopyIconStyled = styled(ContentCopy)(({ theme }) => ({
	fontSize: '14px !important',
	color: theme.custom.colors.kozmikBlue
}))

const GroupStyled = styled((props) => {
	const { isActive, ...restProps } = props

	return <Typography {...restProps} />
})((props) => ({
	...{
		marginRight: 10,
		padding: '5px 10px',
		fontSize: 14,
		lineHeight: '22px',
		fontWeight: '600',
		textAlign: 'center',
		cursor: 'pointer',
		color: props.theme.custom.colors.darkGray,
		border: `1px solid ${props.theme.custom.colors.gray}`,
		borderRadius: '4px',
		[props.theme.breakpoints.down('md')]: {
			padding: '7px 10px'
		}
	},
	...(props.isActive && {
		color: '#000000',
		backgroundColor: props.theme.custom.colors.gray,
		border: `1px solid ${props.theme.custom.colors.gray}`,
		borderRadius: '4px'
	})
}))

const CopyBtnTxtStyled = styled(Typography)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12,
	fontWeight: '600',
	textAlign: 'left',
	textTransform: 'none'
}))

const CopyBtnIconStyled = styled(ContentCopy)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12
}))

const PlatformsStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: 30
}))

const PlatformsInfoStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	marginBottom: 10
}))

const PlatformStyled = styled('div')((props) => ({
	...{
		position: 'relative',
		width: 100,
		height: 100,
		borderRadius: 8,
		cursor: 'pointer',
		backgroundColor: props.theme.custom.colors.kozmikGray,
		border: `1px solid ${props.theme.custom.colors.kozmikGray}`
	},
	...(props.isActive && {
		border: `1px solid ${props.theme.custom.colors.kozmikDarkBlue}`
	})
}))

const ContentTitleStyled = styled(Typography)(({ theme }) => ({
	marginBottom: 5,
	fontSize: 16,
	fontWeight: '600'
}))

const ContentDescStyled = styled(Typography)(({ theme }) => ({
	marginBottom: 10,
	fontSize: 12,
	color: theme.custom.colors.darkGray,
	opacity: 0.7
}))

const HistoryModalBoxStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100%',
	padding: 30
}))

const HistoryTitleStyled = styled(Typography)(({ theme }) => ({
	marginBottom: 15,
	fontSize: 16,
	fontWeight: '600'
}))

const CredentialsStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.red,
	textAlign: 'center',
	paddingTop: 5,
	paddingBottom: 10
}))

const AppInfoStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	borderBottom: `1px solid ${theme.custom.colors.gray}`,
	paddingBottom: 30,
	marginTop: 20,
	marginBottom: 20,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	justifyContent: 'flex-start'
}))

const AppInfoLeftStyled = styled(Box)(({ theme }) => ({ marginRight: 10 }))

const AppInfoRightStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'center'
}))

const AppIconStyled = styled(CardMedia)(({ theme }) => ({
	width: 70,
	height: 70,
	borderRadius: 8
}))

const AppNameStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	fontWeight: 'bold',
	color: theme.custom.colors.darkGray
}))

const SeeHistoryStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.kozmikBlue,
	cursor: 'pointer'
}))

const PublishButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.kozmikDarkBlue,
	fontWeight: 'bold'
}))

const TemplateSubmitButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.kozmikDarkBlue
}))

const ButtonTextStyled = styled(Typography)(({ theme }) => ({
	color: '#FFFFFF',
	fontSize: 14,
	fontWeight: 'bold'
}))

const PrivacyGroupStyled = styled(Box)(({ theme }) => ({
	borderBottom: `1px solid ${theme.custom.colors.gray}`,
	paddingBottom: 10,
	marginBottom: 20
}))

const PrivacyGroupLastStyled = styled(PrivacyGroupStyled)(({ theme }) => ({
	borderBottom: 'unset'
}))

const NotPublishedStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.darkGray,
	opacity: 0.7
}))

const RadioBoxDescStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.darkGray,
	opacity: 0.7,
	marginBottom: 9,
	marginTop: -5
}))

const AppHeader = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	let { appId } = useParams()

	const defaultMessage = t('appUsers.inviteModal.defaultMessage')

	const [templateSubmitVisible, setTemplateSubmitVisible] = useState(false)
	const [successVisible, setSuccessVisible] = useState(false)
	const [historyVisible, setHistoryVisible] = useState(false)
	const [isPublished, setIsPublished] = useState(false)

	const [appName, setAppName] = useState('')
	const [icon, setIcon] = useState(null)
	const [accentColor, setAccentColor] = useState(null)

	const [history, setHistory] = useState([])

	const [appLink, setAppLink] = useState('')

	/*****PUBLISH_MODULE*****/
	const [publishPop, setPublishPop] = useState(null)

	const openPublishPop = (event) => {
		setPublishPop(event.currentTarget)
	}

	const closePublishPop = () => {
		setPublishPop(null)
	}
	const publishPopOpen = Boolean(publishPop)

	/*****SHARE_MODULE*****/
	const [sharePop, setSharePop] = useState(null)

	const openSharePop = (event) => {
		setSharePop(event.currentTarget)
	}

	const closeSharePop = () => {
		setSharePop(null)
	}
	const sharePopOpen = Boolean(sharePop)

	/****SUBDOMAIN***************************/
	const [subDomain, setSubDomain] = useState('')
	const changeSubDomain = (e) => {
		timeoutDelay(submitSubDomain, e.target.value, 1000)
		setSubDomain(e.target.value)
	}
	const submitSubDomain = (sDomain) => {
		postAppDetailData('ShortName', sDomain, initialization)
	}

	/****INVITE***************************/
	const [emails, setEmails] = useState([])
	const [isEmailValid, setIsEmailValid] = useState(true)
	const [inviteMessage, setInviteMessage] = useState(defaultMessage)

	const validateEmail = (email) => {
		return new Promise((resolve) => {
			const re = /\S+@\S+\.\S+/
			const isValid = re.test(email)
			if (isValid || email == '') {
				setIsEmailValid(true)
			} else {
				setIsEmailValid(false)
			}
			resolve(isValid)
		})
	}

	const inviteUsers = () => {
		const data = {
			Emails: emails,
			Message: inviteMessage
		}
		appUserClient.invite(appId, data).then((response) => {
			if (response?.status == 200) {
				if (response?.data?.every((x) => x?.success)) {
					setSharePop(false)
				} else if (response?.data?.every((x) => !x?.success)) {
					toast.error(response?.data?.[0].errorDetails)
				} else if (response?.data?.some((x) => !x?.success)) {
					toast.error(t('appUsers.inviteToast.partial'))
					setSharePop(false)
				}
			} else {
				showToastResponseError(response)
			}
		})
	}

	const copyToClipboard = (val) => {
		navigator.clipboard.writeText(val)
	}

	/****INVITE_ONLY***************************/
	const [radioInviteVal, setRadioInviteVal] = useState(false)
	const changeRadioInviteVal = (e) => {
		submitInviteOnly(e.target.value)
		setRadioInviteVal(e.target.value)
	}
	const submitInviteOnly = (val) => {
		postAppDetailData('InviteOnly', val, initialization)
	}

	/****SIGN_IN_REQUIRED***************************/
	const [radiosSignInRequiredVal, setRadiosSignInRequiredVal] = useState(false)
	const changeRadioSignInRequiredVal = (e) => {
		submitSignInRequired(e.target.value)
		setRadiosSignInRequiredVal(e.target.value)
	}
	const submitSignInRequired = (val) => {
		postAppDetailData('SignInRequired', val, initialization)
	}

	/****LOGIN_METHOD***************************/
	const [loginMethod, setLoginMethod] = useState(null)
	const changeRadioLoginMethodVal = (e) => {
		setLoginMethod(e.target.value)
		submitLoginMethod(e.target.value)
	}
	const submitLoginMethod = (val) => {
		postAppDetailData('LoginMethod', val, initialization)
	}

	const platforms = [
		{
			text: 'Web',
			value: 'web',
			icon: PublishWeb,
			plans: ['free', 'starter', 'pro', 'business']
		},
		{
			text: 'App Store',
			value: 'ios',
			icon: PublishAppStore,
			plans: ['pro', 'business']
		},
		{
			text: 'Play Store',
			value: 'android',
			icon: PublishGooglePlay,
			plans: ['pro', 'business']
		}
	]

	/****PLATFORM***************************/
	const [platform, setPlatform] = useState('web')

	/****SUBMIT***************************/
	const submit = () => {
		if (!platform) return

		appClient.appPublish(appId, platform).then((response) => {
			if (response?.data?.success) {
				updateReduxDetailData('Published', true)

				setAppLink(response.data?.data?.start_url)
				setSuccessVisible(true)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const initialization = (appDetail) => {
		getHistory()

		setAppName(appDetail?.Name || '')
		setIcon(appDetail?.Icon || '')

		setIsPublished(appDetail?.Published || false)

		setAppLink(`https://${appDetail?.ShortName || ''}.kozmik.app`)
		setSubDomain(appDetail?.ShortName || '')

		setRadioInviteVal(appDetail?.InviteOnly || false)
		setRadiosSignInRequiredVal(appDetail?.SignInRequired || false)
		setLoginMethod(appDetail?.LoginMethod || null)

		setAccentColor(appDetail?.AccentColor || null)
	}

	const initializationSetting = (setting) => {
		setWebConfigured(true)
		setAndroidConfigured(setting.AndroidConfigured)
		setIosConfigured(setting.IOSConfigured)
	}

	const postAppDetailData = (propName, propData, callback = null) => {
		if (appId) {
			let updateData = {
				Id: appId
			}
			updateData[propName] = propData

			appClient
				.update(updateData)
				.then((response) => {
					if (response.status == 200) {
						updateReduxDetailData(propName, propData)
					} else {
						showToastResponseError(response)
						callback()
					}
				})
				.catch((error) => {
					callback()
				})
		}
	}

	const updateReduxDetailData = (propName, propData) => {
		let tempData = { ...props.appDetail }
		tempData[propName] = propData
		props.setDetail(tempData)
	}

	const groups = [
		{
			name: 'publish',
			title: t('publishModule.groups.publish')
		},
		{
			name: 'privacy',
			title: t('publishModule.groups.privacy')
		}
	]

	const [group, setGroup] = useState('publish')
	const [webConfigured, setWebConfigured] = useState(false)
	const [androidConfigured, setAndroidConfigured] = useState(false)
	const [iosConfigured, setIosConfigured] = useState(false)

	const [submitButtonActive, setSubmitButtonActive] = useState(true)

	const checkSubmitButtonActive = (platform) => {
		switch (platform) {
			case 'web':
				setSubmitButtonActive(webConfigured)
				break
			case 'android':
				setSubmitButtonActive(androidConfigured)
				break
			case 'ios':
				setSubmitButtonActive(iosConfigured)
				break

			default:
				break
		}
	}

	const getHistory = () => {
		appClient.getHistory(appId).then((response) => {
			if (response?.data?.success) {
				setHistory(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	useEffect(() => {
		if (sharePop) {
			setEmails([])
			setIsEmailValid(true)
			setInviteMessage(defaultMessage)
		}
	}, [sharePop])

	useEffect(() => {
		props.appDetail && initialization(props.appDetail)
	}, [props.appDetail])

	useEffect(() => {
		props.setting && initializationSetting(props.setting)
	}, [props.setting])

	return (
		<>
			<MainContainerStyled>
				<LeftContainerStyled>
					<Link to={'/'}>
						<TextBoxStyled>
							<ArrowBackIos style={{ fontSize: 14, color: '#464646' }} />
							<TextNameStyled>{appName}</TextNameStyled>
							{isPublished ? (
								<IsPublishedBadgeStyled component={'span'}>
									{t('appHeader.published')}
								</IsPublishedBadgeStyled>
							) : null}
						</TextBoxStyled>
					</Link>
				</LeftContainerStyled>
				<CenterContainerStyled
					style={{ display: props.previewButtonsVisible ? 'flex' : 'none' }}
				>
					<PreviewButtons
						previewButtonIndex={props.previewButtonIndex}
						setPreviewButtonIndex={props.setPreviewButtonIndex}
					/>
				</CenterContainerStyled>
				<RightContainerStyled>
					{props.appDetail?.Published && (
						<ShareStyled color='primary' variant='text' onClick={openSharePop}>
							<ShareTextStyled>{t('appHeader.share')}</ShareTextStyled>{' '}
						</ShareStyled>
					)}

					<PublishStyled
						color='primary'
						variant='contained'
						onClick={openPublishPop}
					>
						<PublishTextStyled>{t('appHeader.publish')}</PublishTextStyled>{' '}
					</PublishStyled>
				</RightContainerStyled>
			</MainContainerStyled>

			<Popover
				id={'share-popover'}
				open={sharePopOpen}
				anchorEl={sharePop}
				onClose={closeSharePop}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: -10,
					horizontal: 'center'
				}}
			>
				<ModalContainerStyled>
					<ModalQrContainerStyled>
						<Box>
							<QRCode
								value={appLink}
								style={{ ...style.qrCode, ...{ width: 100, height: 100 } }}
							/>
						</Box>
						<ModalLinkContainerStyled>
							<Box style={{ width: '100%' }}>
								<InputStyled
									fullWidth
									placeholder={t('publishModule.privacy.subDomainPlaceholder')}
									style={{
										backgroundColor: theme.custom.colors.kozmikGray,
										overflow: 'hidden'
									}}
									value={subDomain}
									onChange={changeSubDomain}
									slotProps={{
										input: {
											startAdornment: (
												<InputAdornment position='start'>
													<AdornmentTextStyled>https://</AdornmentTextStyled>
												</InputAdornment>
											),

											endAdornment: (
												<InputAdornment position='end'>
													<AdornmentTextStyled>.kozmik.app</AdornmentTextStyled>
												</InputAdornment>
											)
										}
									}}
								/>
							</Box>
							<Box
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start'
								}}
							>
								<a href={appLink} target='_blank' rel='noreferrer'>
									<AppLinkAdornmentStyled>
										<AdornmentIconStyled />
										{t('appHeader.visit')}
									</AppLinkAdornmentStyled>
								</a>

								<Button
									startIcon={<CopyIconStyled />}
									size='large'
									variant='contained'
									onClick={() => copyToClipboard(appLink)}
									sx={{
										textTransform: 'none',
										backgroundColor: `transparent !important`,
										boxShadow: 'none !important',
										fontSize: 12,
										borderRadius: '4px',
										margin: '5px'
									}}
								>
									<CopyTextStyled>{t('appHeader.copy')}</CopyTextStyled>
								</Button>
							</Box>
						</ModalLinkContainerStyled>
					</ModalQrContainerStyled>

					<Box>
						<ModalTitleStyled>
							{t('appUsers.inviteModal.title')}
						</ModalTitleStyled>
						<ModalSubTitleStyled>
							{t('appUsers.inviteModal.subTitle')}
						</ModalSubTitleStyled>
						<TagInput
							error={!isEmailValid}
							tags={emails}
							setTags={setEmails}
							onInputChange={(e) => validateEmail(e.target.value)}
							placeholder={t('appUsers.inviteModal.emailPlaceholder')}
						/>
						<ModalMultilineInputStyled
							fullWidth
							placeholder={t('appUsers.inviteModal.messagePlaceholder')}
							value={inviteMessage}
							onChange={(e) => setInviteMessage(e.target.value)}
							multiline
							rows={3}
						/>
					</Box>

					<Box
						style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
					>
						<Button
							fullWidth
							disabled={!isEmailValid || !emails?.length}
							size='large'
							variant='contained'
							onClick={inviteUsers}
							sx={{
								textTransform: 'none',
								backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
								color: '#ffffff',
								boxShadow: 'none !important',
								fontSize: '14px',
								borderRadius: '4px',
								border: `1px solid ${theme.custom.colors.kozmikBlue}`,
								opacity: !isEmailValid || !emails?.length ? 0.4 : 1 //for disabled
							}}
						>
							<ModalButtonTextStyled>
								{t('appUsers.inviteModal.submit')}
							</ModalButtonTextStyled>
						</Button>
					</Box>
				</ModalContainerStyled>
			</Popover>

			<Popover
				id={'publish-popover'}
				open={publishPopOpen}
				anchorEl={publishPop}
				onClose={closePublishPop}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: -10,
					horizontal: 'center'
				}}
			>
				<ModalContainerStyled>
					<Box display={'flex'} flexDirection={'column'} marginBottom={0}>
						<Box display={'flex'} flexDirection={'row'} marginBottom={0}>
							{groups.map((i, index) => (
								<GroupStyled
									isActive={group === i.name}
									key={index}
									onClick={() => {
										setGroup(i.name)
									}}
								>
									{i.title}
								</GroupStyled>
							))}
						</Box>

						{group === 'publish' && (
							<Box style={{ width: '100%' }}>
								<AppInfoStyled>
									<AppInfoLeftStyled>
										<AppIconStyled
											image={icon}
											src={Kozmik}
											component='img'
											style={{
												backgroundColor: accentColor
											}}
										/>
									</AppInfoLeftStyled>
									<AppInfoRightStyled>
										<div style={{ marginTop: 10 }}>
											<AppNameStyled>{appName}</AppNameStyled>
										</div>

										<div
											style={{ marginTop: 10 }}
											onClick={() => setHistoryVisible(true)}
										>
											<SeeHistoryStyled>
												{t('publishModule.publish.history')}
											</SeeHistoryStyled>
										</div>
									</AppInfoRightStyled>
								</AppInfoStyled>
								<Box style={{ width: '100%' }}>
									<PlatformsInfoStyled>
										{t('publishModule.publish.selectPlatform')}
									</PlatformsInfoStyled>
								</Box>
								<PlatformsStyled>
									{platforms.map((p, i) => {
										const authority = p.plans.some((x) =>
											props?.currentOrg?.plan?.includes(x)
										)

										return (
											<PlatformStyled
												isActive={platform === p.value}
												key={i}
												onClick={() => {
													if (authority) {
														setPlatform(p.value)
														checkSubmitButtonActive(p.value)
													} else {
														window.location.href = '/subscription?modal=true'
													}
												}}
											>
												<p.icon
													key={i}
													sx={{ ...(!authority && { opacity: 0.4 }) }}
												/>

												{!authority && (
													<BadgeStyled>
														{t('appIntegrationsTemp.upgrade')}
													</BadgeStyled>
												)}
											</PlatformStyled>
										)
									})}
								</PlatformsStyled>
								<Box style={{ width: '100%' }}>
									{!submitButtonActive && (
										<CredentialsStyled>
											{t('publishModule.publish.credentials')}
										</CredentialsStyled>
									)}
									<Button
										fullWidth
										disabled={!submitButtonActive}
										size='small'
										variant='contained'
										onClick={() => submit()}
										sx={{
											textTransform: 'none',
											backgroundColor: `#6BEFC1 !important`,
											opacity: submitButtonActive ? 1 : 0.4,
											boxShadow: 'none !important',
											height: '40px',
											borderRadius: '4px'
										}}
									>
										<PublishButtonTextStyled>
											{t('publishModule.publish.publish')}
										</PublishButtonTextStyled>
									</Button>
								</Box>

								{props.userInfo?.email === 'ahmet@kozmik.io' && (
									<Box style={{ width: '100%', marginTop: 10 }}>
										<Button
											fullWidth
											disabled={!submitButtonActive}
											size='small'
											variant='contained'
											onClick={() => {
												setTemplateSubmitVisible(true)
											}}
											sx={{
												textTransform: 'none',
												backgroundColor: `transparent !important`,
												boxShadow: 'none !important',
												height: '40px'
											}}
										>
											<TemplateSubmitButtonTextStyled>
												{t('publishModule.publish.submitTemplate')}
											</TemplateSubmitButtonTextStyled>
										</Button>
									</Box>
								)}
							</Box>
						)}

						{group === 'privacy' && (
							<Box style={{ padding: 10 }}>
								<PrivacyGroupStyled>
									<ContentTitleStyled>
										{t('publishModule.privacy.signUpTitle')}
									</ContentTitleStyled>
									<ContentDescStyled>
										{t('publishModule.privacy.signUpDesc')}
									</ContentDescStyled>
									<FormControl style={{ width: '100%' }}>
										<RadioGroup
											value={radioInviteVal}
											onChange={changeRadioInviteVal}
											style={{ width: '100%' }}
										>
											<Box>
												<FormControlLabel
													slotProps={{ typography: { fontSize: 14 } }}
													value={true}
													control={<Radio size='small' />}
													label={t('publishModule.privacy.signUpPrivate')}
												/>
											</Box>
											<Box>
												<FormControlLabel
													slotProps={{ typography: { fontSize: 14 } }}
													value={false}
													control={<Radio size='small' />}
													label={t('publishModule.privacy.signUpPublic')}
												/>
											</Box>
										</RadioGroup>
									</FormControl>
								</PrivacyGroupStyled>
								<PrivacyGroupStyled>
									<ContentTitleStyled>
										{t('publishModule.privacy.signInTitle')}
									</ContentTitleStyled>
									<ContentDescStyled>
										{t('publishModule.privacy.signInDesc')}
									</ContentDescStyled>
									<FormControl style={{ width: '100%' }}>
										<RadioGroup
											value={radiosSignInRequiredVal}
											onChange={changeRadioSignInRequiredVal}
											style={{ width: '100%' }}
										>
											<Box>
												<Grid container size={{ xs: 12 }}>
													<Grid size={{ xs: 12 }}>
														<FormControlLabel
															slotProps={{ typography: { fontSize: 14 } }}
															value={true}
															control={<Radio size='small' />}
															label={t('publishModule.privacy.signInRequired')}
														/>
													</Grid>
													<Grid size={{ xs: 12 }}>
														<RadioBoxDescStyled>
															{t('publishModule.privacy.signInRequiredDesc')}
														</RadioBoxDescStyled>
													</Grid>
												</Grid>
											</Box>
											<Box>
												<Grid container size={{ xs: 12 }}>
													<Grid size={{ xs: 12 }}>
														<FormControlLabel
															slotProps={{ typography: { fontSize: 14 } }}
															value={false}
															control={<Radio size='small' />}
															label={t('publishModule.privacy.signInOptional')}
														/>
													</Grid>
													<Grid size={{ xs: 12 }}>
														<RadioBoxDescStyled>
															{t('publishModule.privacy.signInOptionalDesc')}
														</RadioBoxDescStyled>
													</Grid>
												</Grid>
											</Box>
										</RadioGroup>
									</FormControl>
								</PrivacyGroupStyled>
								<PrivacyGroupLastStyled>
									<ContentTitleStyled>
										{t('publishModule.privacy.authenticationTitle')}
									</ContentTitleStyled>
									<ContentDescStyled>
										{t('publishModule.privacy.authenticationDesc')}
									</ContentDescStyled>

									<FormControl style={{ width: '100%' }}>
										<RadioGroup
											value={loginMethod}
											onChange={changeRadioLoginMethodVal}
											style={{ width: '100%' }}
										>
											<Box>
												<Grid container size={{ xs: 12 }}>
													<Grid size={{ xs: 12 }}>
														<FormControlLabel
															slotProps={{ typography: { fontSize: 14 } }}
															value='EmailPassword'
															control={<Radio size='small' />}
															label={t(
																'publishModule.privacy.authenticationEmailPassword'
															)}
														/>
													</Grid>
													<Grid size={{ xs: 12 }}>
														<RadioBoxDescStyled>
															{t(
																'publishModule.privacy.authenticationEmailPasswordDesc'
															)}
														</RadioBoxDescStyled>
													</Grid>
												</Grid>
											</Box>
											<Box>
												<Grid container size={{ xs: 12 }}>
													<Grid size={{ xs: 12 }}>
														<FormControlLabel
															slotProps={{ typography: { fontSize: 14 } }}
															value='Pin'
															control={<Radio size='small' />}
															label={t(
																'publishModule.privacy.authenticationPin'
															)}
														/>
													</Grid>
													<Grid size={{ xs: 12 }}>
														<RadioBoxDescStyled>
															{t('publishModule.privacy.authenticationPinDesc')}
														</RadioBoxDescStyled>
													</Grid>
												</Grid>
											</Box>
										</RadioGroup>
									</FormControl>
								</PrivacyGroupLastStyled>
							</Box>
						)}
					</Box>
				</ModalContainerStyled>
			</Popover>

			<KozmikModal
				visible={successVisible}
				hideModal={() => setSuccessVisible(false)}
				flexHeight
				sm
			>
				<SuccessModalBoxStyled container justifyContent='center'>
					<Grid
						container
						size={{ xs: 12 }}
						alignItems='center'
						style={{ marginBottom: 20 }}
					>
						<CardMedia
							image={successImage}
							component='img'
							style={{ width: 52, height: 52 }}
						/>
						<SuccessTitleStyled>
							{t('publishModule.webModal.success.title')}
						</SuccessTitleStyled>
					</Grid>
					<Grid size={{ xs: 12 }} container justifyContent='center'>
						<QRCode
							value={appLink}
							style={{ ...style.qrCode, ...{ width: 150, height: 150 } }}
						/>
					</Grid>
					<Grid size={{ xs: 12 }} style={{ marginBottom: 20 }}>
						<SuccessSubTitleStyled>
							{t('publishModule.webModal.success.appLinkTitle')}
						</SuccessSubTitleStyled>
						<Grid
							container
							size={{ xs: 12 }}
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							style={{ marginBottom: 10 }}
						>
							<Grid size={{ xs: 9 }}>
								<InputStyled
									fullWidth
									style={{
										backgroundColor: theme.custom.colors.kozmikGray,
										overflow: 'hidden'
									}}
									value={appLink}
									slotProps={{
										input: {
											endAdornment: (
												<InputAdornment position='end'>
													<a href={appLink} target='_blank' rel='noreferrer'>
														<AppLinkAdornmentStyled>
															<AdornmentIconStyled />
															{t('publishModule.webModal.success.visit')}
														</AppLinkAdornmentStyled>
													</a>
												</InputAdornment>
											)
										}
									}}
								/>
							</Grid>
							<Grid size={{ xs: 3 }}>
								<Button
									startIcon={<CopyBtnIconStyled />}
									// fullWidth
									size='large'
									variant='contained'
									onClick={() => copyToClipboard(appLink)}
									sx={{
										textTransform: 'none',
										backgroundColor: `transparent !important`,
										boxShadow: 'none !important',
										fontSize: 14,
										borderRadius: '4px',
										margin: '5px'
									}}
								>
									<CopyBtnTxtStyled>
										{t('publishModule.webModal.success.copy')}
									</CopyBtnTxtStyled>
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid container size={{ xs: 12 }} justifyContent='center'>
						<Button
							onClick={() => setSuccessVisible(false)}
							size='small'
							variant='contained'
							sx={{
								textTransform: 'none',
								backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
								boxShadow: 'none !important',
								height: '40px',
								width: '216px',
								borderRadius: '4px'
							}}
						>
							<ButtonTextStyled>
								{t('publishModule.webModal.success.button')}
							</ButtonTextStyled>
						</Button>
					</Grid>
				</SuccessModalBoxStyled>
			</KozmikModal>

			<KozmikModal
				visible={historyVisible}
				hideModal={() => setHistoryVisible(false)}
				md
			>
				<HistoryModalBoxStyled>
					<HistoryTitleStyled>
						{t('publishModule.androidModal.historyTitle')}
					</HistoryTitleStyled>

					{history?.length > 0 ? (
						history.map((item, index) => (
							<HistoryItem
								platform={history.Platform}
								item={item}
								key={index}
							/>
						))
					) : (
						<NotPublishedStyled>
							{t('publishModule.androidModal.notPublished')}
						</NotPublishedStyled>
					)}
				</HistoryModalBoxStyled>
			</KozmikModal>

			<TemplateSubmit
				visible={templateSubmitVisible}
				hideModal={() => {
					setTemplateSubmitVisible(false)
				}}
			/>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
