import React from 'react'
import { useTheme } from '@mui/material/styles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Box, Button, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'

import authorizationClient from '../../../services/authorizationClient'

const style = {
	accountPopover: (props, theme) => {
		return {
			position: 'absolute',
			right: 12,
			bottom: 50,
			backgroundColor: '#FFFFFF',
			borderRadius: '4px',
			boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)',
			width: '10.2vw',
			padding: '10px',
			[theme.breakpoints.down('md')]: {
				width: '30vw'
			}
		}
	},
	root: (props, theme) => {
		return {
			'& > span': {
				margin: theme.spacing(2)
			}
		}
	},
	userInfo: {
		flexGrow: 1,
		paddingLeft: 5,
		overflow: 'hidden'
	},
	name: (props, theme) => {
		return {
			color: theme.custom.colors.textPrimary,
			fontSize: 12,
			textAlign: 'left',
			fontWeight: 600,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	},
	email: (props, theme) => {
		return {
			color: theme.custom.colors.textPrimary,
			fontSize: 10,
			textAlign: 'left',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	},
	exitIcon: (props, theme) => {
		return {
			color: theme.custom.colors.textPrimary,
			width: '14px'
		}
	},
	accountContainer: (props, theme) => {
		return {
			display: 'flex',
			alignItems: 'center',
			height: 45,
			border: `1px solid ${theme.custom.colors.gray}`,
			borderRadius: 6,
			padding: 5,
			cursor: 'pointer',
			marginBottom: 5
		}
	},
	exitButton: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		marginTop: 15
	},
	exitText: (props, theme) => {
		return {
			paddingLeft: 5,
			color: theme.custom.colors.textPrimary,
			fontSize: 12,
			fontWeight: 'bold'
		}
	},
	subscriptionText: (props, theme) => {
		return {
			paddingBottom: 5,
			color: theme.custom.colors.textPrimary,
			fontSize: 12,
			fontWeight: '600'
		}
	},
	upgradeButton: (props, theme) => {
		return {
			textTransform: 'none',
			backgroundColor: theme.custom.colors.kozmikGreen,
			boxShadow: 'none !important',
			borderRadius: '5px',
			marginLeft: '10px',
			height: '25px'
		}
	},
	btnTxt: (props, theme) => {
		return {
			fontSize: 8,
			color: theme.custom.colors.kozmikDarkBlue,
			fontWeight: 'bold'
		}
	}
}

const Account = (props) => {
	const theme = useTheme()
	const { t } = useTranslation()
	let location = useLocation()

	return (
		<div>
			{!!props?.usage && (
				<Link to={'/subscription'} onClick={props?.collapseHamburger}>
					<div
						style={{
							...style.accountContainer(props, theme),
							...(location?.pathname == '/subscription'
								? { backgroundColor: theme.custom.colors.gray }
								: null)
						}}
					>
						<Box sx={{ flexGrow: 1 }}>
							<div style={style.subscriptionText(props, theme)}>
								{props.usage?.Plan}
							</div>
							<KozmikProgressBar
								barColor={'#ffffff'}
								progressColor={
									props.usage?.Percentage >= 80
										? theme.custom.colors.darkRed
										: theme.custom.colors.kozmikDarkBlue
								}
								variant='determinate'
								value={
									props.usage?.Percentage >= 100 ? 100 : props.usage?.Percentage
								}
							/>
						</Box>
						<Button
							size='small'
							variant='contained'
							style={style.upgradeButton(props, theme)}
						>
							<Typography sx={style.btnTxt(props, theme)}>
								{t('masterAccount.upgrade')}
							</Typography>
						</Button>
					</div>
				</Link>
			)}
			<Link to={'/account'} onClick={props?.collapseHamburger}>
				<div
					style={{
						...style.accountContainer(props, theme),
						...(location?.pathname == '/account'
							? { backgroundColor: theme.custom.colors.gray }
							: null)
					}}
				>
					<ProfileCard
						text={props.userInfo?.name}
						imgUrl={props.userInfo?.photo}
						size={35}
						circle
						bgColor={theme.custom.colors.gray}
					/>
					<div style={style.userInfo}>
						<div style={style.name(props, theme)}>{props.userInfo?.name}</div>
						<div style={style.email(props, theme)}>{props.userInfo?.email}</div>
					</div>
				</div>
			</Link>
			<div
				onClick={() => authorizationClient.logout()}
				style={style.exitButton}
			>
				<ExitToAppIcon sx={style.exitIcon(props, theme)} />
				<span style={style.exitText(props, theme)}>
					{t('masterAccount.logOut')}
				</span>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.usage
})

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		setUserInfo: (x) => dispatch(setUserInfo(x)),
// 		setAuth: (x) => dispatch(setAuth(x))
// 	}
// }

export default connect(mapStateToProps, null)(Account)
